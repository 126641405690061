import styled from 'styled-components'
import PropTypes from 'prop-types'
import testIds from 'enums/testIds'

const Video = ({ videoUrl }) => (
  <VideoMarkup loop="loop" autoPlay="autoPlay" muted="muted">
    <source data-testid={testIds.video} src={videoUrl} type="video/mp4" />
  </VideoMarkup>
)

Video.propTypes = {
  videoUrl: PropTypes.string.isRequired,
}

const VideoMarkup = styled.video`
  width: 100%;
  display: inline-block;
`

export default Video
