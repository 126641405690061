import trackingIds from 'tracking/tracking-ids'
import { HEADER_TYPES } from 'components/editorial/features/feature-header'

const getFeatureContent = (data, headerType) => `
${applyFeatureStyleOverrides(data)}
${getFeatureIntro(data, headerType)}
<section data-tracking-id="${
  trackingIds.feature
}" style="background-color: transparent !important" id="feature-content" class="lineHeight reading-line-height ${
  headerType === HEADER_TYPES.Large ? 'largeFeature' : ''
}">${data.content}</section>
`
const applyFeatureStyleOverrides = (data) => `
  <style>
    #feature {
      ${data.textColor ? `color: #${data.textColor} !important;` : ''}
      ${
        data.backgroundColor
          ? `background-color: #${data.backgroundColor} !important;`
          : ''
      }
    }

    #feature a {
      ${data.linkColor ? `color: #${data.linkColor};` : ''}
    }

    #feature .quote {
      ${data.linkColor ? `color: #${data.linkColor} !important;` : ''}
    }
  </style>
`

const getFeatureIntro = (data, headerType) => {
  if (!data.blurb) {
    return ''
  }

  // YouTube (films) don't have a header - so add it here
  // For non-large features the blurb is in the <FeatureHeader />
  if (
    headerType === HEADER_TYPES.Large ||
    headerType === HEADER_TYPES.Video ||
    headerType === HEADER_TYPES.YouTube
  ) {
    return `<section class="featureSection introRelative" style="background-color: #${data.backgroundColor}">
    <section>
      <p class="intro">${data.blurb}</p>
    </section>
  </section>`
  }

  return ''
}

export default getFeatureContent
export { applyFeatureStyleOverrides }
