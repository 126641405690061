const Areas = {
  Words: 'words',
  Published: 'published',
  PhotoCredit: 'photo-credit',
}

const showRowIf = (condition, row) => (condition ? row : '')

const smallGridArea = (data) => `
  'words'
  'published'
  ${showRowIf(data.photoCredit, `'photo-credit'`)}
`

const mediumGridArea = (data) => `
  'words words published published . . . .'  
  ${showRowIf(
    data.photoCredit,
    `'photo-credit photo-credit photo-credit photo-credit photo-credit photo-credit photo-credit photo-credit'`
  )}
`

const largeGridArea = (data) =>
  data.photoCredit
    ? `'words words published published photo-credit photo-credit photo-credit photo-credit photo-credit photo-credit . .'`
    : `'words words published published . . . . . . . .'`

export { Areas, smallGridArea, mediumGridArea, largeGridArea }
