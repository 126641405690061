const HEADER_TYPES = {
  YouTube: 'YouTube',
  Video: 'Video',
  Large: 'Large',
  Standard: 'Standard',
  Legacy: 'Legacy',
}

const getFeatureHeaderInfo = (data) => {
  const imageObject = data.images.reduce(
    (result, { type, imageUrl }) => ({
      ...result,
      [type]: imageUrl,
    }),
    {}
  )

  const videoUrl = imageObject.VIDEOMP4 || imageObject.VIDEOWEBM
  const largeImageUrl = imageObject.LARGE
  const mobileImageUrl = imageObject.MOBILE
  const standardImageUrl = imageObject.STANDARD

  const isLarge = Boolean(largeImageUrl || videoUrl)

  const srcSet = isLarge
    ? `${largeImageUrl} 3200w, ${mobileImageUrl} 640w`
    : `${standardImageUrl} 1180w, ${mobileImageUrl} 640w`

  const headerType = getHeaderType(data, imageObject)

  return {
    videoUrl,
    largeImageUrl,
    mobileImageUrl,
    standardImageUrl,
    isLarge,
    srcSet,
    headerType,
  }
}

const getHeaderType = (data, images) => {
  if (data.type === 'FILM' && data.youtubeId) {
    return HEADER_TYPES.YouTube
  }

  if (images.VIDEOMP4 || images.VIDEOWEBM) {
    return HEADER_TYPES.Video
  }

  if (images.LARGE) {
    return HEADER_TYPES.Large
  }

  if (images.STANDARD) {
    return HEADER_TYPES.Standard
  }

  return HEADER_TYPES.Legacy
}

export { HEADER_TYPES, getFeatureHeaderInfo }
