/* eslint-disable func-names */
import $ from 'jquery'

const imagesLoaded = require('imagesloaded')

const Feature = {
  ResetFeature() {
    Feature.StatsTime = 2000
    Feature.StickyHeader = false
    Feature.ScrolledPastHeader = false
  },

  RemoveEventHandlers() {
    $(window).off()
  },

  InitWhenReady() {
    $(document).ready(function () {
      Feature.ResetFeature()
      Feature.Init()
      Feature.InitEffects()
    })
  },

  Init() {
    $.each($('.featureSection.gallery'), function (index, value) {
      Feature.BindGalleryActions($(value))
    })

    if (
      $('.featureSection.headerContents').length === 0 &&
      $('.featureSection.headerVideo').length === 0
    ) {
      return
    }

    Feature.SetupScroll()
    Feature.SetupContents($('.featureSection.headerContents'))
    Feature.SetupScrollTo()

    $(window).resize(function () {
      $('.featureSection.headerContents').css(
        'height',
        $('.featureSection.headerContents')
          .find('div.image > img:visible')
          .height()
      )
    })
  },

  InitEffects() {
    // We are loading some advanced effects in some features
    // https://app.shortcut.com/resident-advisor/story/34095/init-advanced-feature-effects
    if (
      window.imagesLoaded &&
      window.jQuery &&
      window.gsap &&
      window.initAdvancedFeatureEffects &&
      typeof window.initAdvancedFeatureEffects === 'function'
    ) {
      window.initAdvancedFeatureEffects()
    }
  },

  SetupScroll() {
    $(window).on('scroll', function () {
      $.each($('.featureSection.headerContents'), function (index, value) {
        Feature.ScrollContents($(value))
      })

      $.each($('.featureSection.chapter'), function (index, value) {
        Feature.ScrollChapter($(value))
      })

      $.each($('.featureSection.stats'), function (index, value) {
        Feature.ScrollStats($(value))
      })

      $.each($('.featureSection.nav'), function (index, value) {
        Feature.ScrollNav($(value))
      })
    })
  },

  SetupContents($contents) {
    if ($contents.length === 0) {
      return
    }

    if ($('ul.headerContents').length > 0) {
      $('.featureSection.headerContents .image').append($('ul.headerContents'))
      $('.featureSection.headerContents').addClass('sticky')
      Feature.StickyHeader = true
    }

    imagesLoaded($contents, function () {
      const imageHeight = $contents.find('div.image > img:visible').height()
      $contents.css('height', imageHeight)
    })

    Feature.SetChapterAnimationDelay($contents)
  },

  SetChapterAnimationDelay($contents) {
    $.each($contents.find('li'), function (index, value) {
      let animationDelay
      if (!Feature.ScrolledPastHeader) {
        animationDelay = `${1 + index / 5}s`
      } else {
        animationDelay = '0s'
      }

      $(value).css('animation-delay', animationDelay)
      $(value).css('-webkit-animation-delay', animationDelay)
      $(value).css('-moz-animation-delay', animationDelay)
      $(value).css('-o-animation-delay', animationDelay)
    })
  },

  ScrollContents($header) {
    const top = $(window).scrollTop()
    const bottom = top + $(window).height()

    const start = $header.offset().top
    const end = $header.offset().top + $header.height()

    if (top >= start && top <= end && end < bottom) {
      if ($header.height() > $(window).height() && Feature.StickyHeader) {
        $header.addClass('on')
        $header.addClass('bottom')
      } else if (Feature.StickyHeader) {
        $header.addClass('on')
      }
    } else {
      $header.removeClass('on')
      $header.removeClass('bottom')

      if (top > end && !Feature.ScrolledPastHeader) {
        // After scrolling past the header the first time, remove the animation delay
        Feature.ScrolledPastHeader = true
        Feature.SetChapterAnimationDelay($header)
      }
    }
  },

  ScrollChapter($chapter) {
    const top = $(window).scrollTop() + 200
    const start = $chapter.offset().top

    if (top >= start) {
      $chapter.find('.line').addClass('on')
    }
  },

  ScrollStats($stat) {
    if ($stat.data('animated')) {
      return
    }

    const top = $(window).scrollTop() + 1000
    const start = $stat.offset().top

    if (top >= start) {
      $stat.data('animated', true)
      Feature.WriteText($stat)
      Feature.CountUp($stat)
    }
  },

  ScrollNav($nav) {
    const top = $(window).scrollTop()
    const start = $('.navStart').offset().top
    const end = $('.navEnd').offset().top

    if (top >= start && top <= end) {
      $nav.addClass('on')
    } else {
      $nav.removeClass('on')
    }
  },

  ScrollGallery($gallery) {
    if ($gallery.data('animated')) {
      return
    }

    const top = $(window).scrollTop() + 200
    const start = $gallery.offset().top

    if (top >= start) {
      $gallery.data('animated', true)
      $gallery.find('ul').addClass('on')
    }
  },

  BindGalleryActions($gallery) {
    const totalItems = $gallery.find('a').length
    const prefix = $gallery.find('a').length < 10 ? '0' : ''

    $gallery
      .find('div.number')
      .html(
        `<div>01</div><div class="line"></div><div>${prefix}${totalItems}</div>`
      )
    $gallery
      .find('div.byline')
      .text($gallery.find('a').first().find('img').attr('alt'))

    $gallery.find('a').each(function (index, item) {
      $(item).click(function (e) {
        e.preventDefault()
        $gallery.find('.image').attr('src', $(item).find('img').attr('src'))
        $gallery
          .find('div.number')
          .html(
            `<div>${index < 9 ? '0' : ''}${
              index + 1
            }</div><div class="line"></div><div>${prefix}${totalItems}</div>`
          )

        $gallery.find('div.byline').text('')
        $gallery.find('div.byline').text($(item).find('img').attr('alt'))
      })
    })
  },

  CountUp($stat) {
    $stat.find('.number').each(function (index, item) {
      const number = $(item).text()
      $(item).fadeIn()

      $({ countNum: 0 }).animate(
        { countNum: number },
        {
          duration: Feature.StatsTime,
          easing: 'linear',
          step() {
            $(item).text(this.countNum.toFixed(0))
          },
          complete() {
            $(item).text(number)
          },
        }
      )
    })
  },

  WriteText($stat) {
    $stat.find('.text').each(function (index, item) {
      const text = $(item).text()
      $(item).text('')
      $(item).fadeIn()

      Feature.WriteCharacter($(item), text, 0, Feature.StatsTime / text.length)
    })
  },

  WriteCharacter($target, message, index, interval) {
    if (index < message.length) {
      // eslint-disable-next-line no-param-reassign
      $target.append(message[index++])
      setTimeout(function () {
        Feature.WriteCharacter($target, message, index, interval)
      }, interval)
    }
  },

  SetupScrollTo() {
    $.each($('.featureSection.headerContents a'), function (index, value) {
      $(value).click(function (e) {
        e.preventDefault()

        $('html, body').animate(
          {
            scrollTop: $($(this).attr('href')).offset().top,
          },
          {
            duration: 800,
            complete() {
              window.location.hash = $(value).attr('href')
            },
          }
        )
      })
    })
  },
}

export default Feature
