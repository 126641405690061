import { useQuery } from '@apollo/client'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import { useRouter } from 'next/router'
import Header from 'components/generic/header'
import magazine from 'messages/magazine'
import GET_FEATURE_ARTICLE_HEADER from './GetFeatureArticleHeaderQuery'

const FeatureArticleHeader = ({ id }) => {
  const { data } = useQuery(GET_FEATURE_ARTICLE_HEADER, {
    variables: { id },
  })

  return <FeatureArticleHeaderMarkup data={data} />
}

FeatureArticleHeader.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
}

const FeatureArticleHeaderMarkup = ({ data }) => {
  const intl = useIntl()
  const { pathname } = useRouter()

  const breadcrumb = []

  if (pathname.startsWith('/films')) {
    breadcrumb.push({
      href: '/films',
      text: intl.formatMessage(magazine.films),
    })
  }

  if (pathname.startsWith('/features')) {
    breadcrumb.push({
      href: '/features',
      text: intl.formatMessage(magazine.features),
    })
  }

  const series = data?.feature?.series

  if (series && series.contentUrl && series.title) {
    breadcrumb.push({ href: series.contentUrl, text: series.title })
  }

  return (
    <Header
      text={data?.feature?.title}
      breadcrumb={breadcrumb}
      dynamicFontSize
    />
  )
}

FeatureArticleHeaderMarkup.propTypes = {
  data: PropTypes.shape({
    feature: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      title: PropTypes.string,
      series: PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        contentUrl: PropTypes.string,
        title: PropTypes.string,
      }),
    }),
  }),
}

export { FeatureArticleHeaderMarkup }
export default FeatureArticleHeader
