import FeatureArticle from 'components/editorial/pages/feature'
import WithDefaultLayout from 'components/generic/layouts/default'
import Throw404 from 'components/generic/throw-404'
import isValidId from 'lib/validId'
import ENTITY_TYPE from 'enums/entity-type'

import { useRouter } from 'next/router'

const Features = () => {
  // Matches /features/[id]
  const {
    query: { id },
  } = useRouter()

  if (!isValidId(id)) return <Throw404 entityType={ENTITY_TYPE.Feature} />

  return <FeatureArticle id={id} />
}

export default WithDefaultLayout(Features)
