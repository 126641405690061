import { gql } from '@apollo/client'

const GET_FEATURE_ARTICLE = gql`
  query GET_FEATURE_ARTICLE($id: ID!) {
    feature(id: $id) {
      id
      title
      content
      date
      blurb
      contentUrl
      backgroundColor
      textColor
      linkColor
      tech
      featured
      images {
        type
        imageUrl
      }
      author {
        id
        name
        imageUrl
        username
        contributor
      }
      photoCredit
      series {
        id
        seriesRef
        title
        contentUrl
      }
      youtubeId
      type
      locale
      artists {
        id
        name
        isFollowing
        contentUrl
        image
      }
      localised {
        id
        contentUrl
        locale
      }
    }
  }
`

export default GET_FEATURE_ARTICLE
