import { gql } from '@apollo/client'

const GET_FEATURE_ARTICLE_HEADER = gql`
  query GET_FEATURE_ARTICLE_HEADER($id: ID!) {
    feature(id: $id) {
      id
      title
      series {
        id
        seriesRef
        title
        contentUrl
      }
    }
  }
`

export default GET_FEATURE_ARTICLE_HEADER
