import { useEffect } from 'react'
import canUseDom from 'lib/canUseDom'
import LazyLoad from 'vanilla-lazyload'

let lazyLoadInstance = null
const getLazyLoadInstance = () => {
  if (!lazyLoadInstance) {
    lazyLoadInstance = new window.LazyLoad({
      elements_selector: '.lazy',
    })
  }

  return lazyLoadInstance
}

const useLazyLoad = () => {
  useEffect(() => {
    if (canUseDom()) {
      if (!window.LazyLoad) {
        window.LazyLoad = LazyLoad
      }

      const instance = getLazyLoadInstance()

      instance.update()
    }
  }, [])
}

export default useLazyLoad
